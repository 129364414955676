<!--会员提现-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <div>
                    <label>场景：</label>
                    <el-select v-model="select.status" placeholder="审核状态" filterable clearable class="selectWidth" @change="curr=1;getList()">
                        <el-option v-for="item in selectData" :label="item.name" :value="item.id" :key="item.id"></el-option>
                    </el-select>
                    <el-date-picker v-model="select.create_time" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="提现时间" end-placeholder="筛选" value-format="yyyy-MM-dd" @change="curr=1;getList()">
                    </el-date-picker>
                </div>
            </div>
        </div>

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="user.realname" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="user.mobile" label="手机号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="user.card" label="会员卡" show-overflow-tooltip></el-table-column>
                <el-table-column prop="user.nickname" label="昵称" show-overflow-tooltip></el-table-column>
                <el-table-column label="头像" prop="" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.user && scope.row.user.headimgurl" class="tableImage" :src="scope.row.user.headimgurl" :preview-src-list="[scope.row.user.headimgurl]"></el-image>
                        <el-avatar v-else shape="square" :size="45" :src="require('../../assets/ava.png')"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="amount" label="提现金额" show-overflow-tooltip></el-table-column>
                <el-table-column prop="status_name" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span :class="[scope.row.status == 11 ? 'errorColor' : scope.row.status == 10 ? 'successColor' : scope.row.status == 0 ? 'primaryColor' : scope.row.status == 1 ? 'errorColor' : '' ]">{{scope.row.status_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status_note" label="拒绝原因" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="create_time" label="提现时间" show-overflow-tooltip></el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {memberCashListApi}  from '@/api/member.js';
    export default {
        data() {
            return {
                select:{},
                selectData:[{
                    id:0,
                    name:'审核中'
                },{
                    id:10,
                    name:'已通过'
                },{
                    id:11,
                    name:'已拒绝'
                }], //审核状态下拉选择列表
                list:[], //提现列表

                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            //会员提现列表
            getList: function(){
                memberCashListApi({
                    curr:this.curr,
                    row:this.row,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                    
                })
            },

            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>